import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Type', 'messageType', true, 'desc', 'left'),
  new TableColumn('Rego', 'regoNo', true, 'desc', 'left'),
  new TableColumn('Claim No.', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Customer', 'customerName', true, 'desc', 'left'),
  new TableColumn('Job ID', 'uid', true, 'desc', 'left'),
  new TableColumn('Quote No.', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Vehicle', 'vehicleDescription', true, 'desc', 'left'),
  new TableColumn('Message Date', 'MessageDateUtc', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
