<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="$userInfo.isSupportUser"
      :show-print="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <estimage-message-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="tabs is-toggle is-toggle-rounded">
              <ul>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.UnRead }">
                  <a @click="tabClicked(messageStatusTypes.UnRead)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email mdi-18px" />
                    </span>
                    <span>
                      <span :class="unreadOffset">Unread</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.UnRead ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.UnRead)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Read }">
                  <a @click="tabClicked(messageStatusTypes.Read)">
                    <span class="icon is-small">
                      <i class="mdi mdi-email-open mdi-18px" />
                    </span>
                    <span>
                      <span :class="readOffset">Read</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Read ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.ToSend }">
                  <a @click="tabClicked(messageStatusTypes.ToSend)">
                    <span class="icon is-small">
                      <i class="mdi mdi-send mdi-18px" />
                    </span>
                    <span>
                      <span :class="toSendOffset">To Send</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.ToSend ? 'has-badge-info' : 'has-badge-dark' ]"
                        :data-badge="getCount(messageStatusTypes.ToSend)" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Sent }">
                  <a @click="tabClicked(messageStatusTypes.Sent)">
                    <span class="icon is-small">
                      <i class="mdi mdi-checkbox-marked-circle mdi-18px" />
                    </span>
                    <span>
                      <span :class="sentOffset">Sent</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Sent ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
                <li :class="{ 'is-active': filter.messageStatusId == messageStatusTypes.Deleted }">
                  <a @click="tabClicked(messageStatusTypes.Deleted)">
                    <span class="icon is-small">
                      <i class="mdi mdi-delete mdi-18px" />
                    </span>
                    <span>
                      <span :class="deletedOffset">Deleted</span>
                      <span v-if="countSummaries"
                        class="has-badge-rounded has-badge-medium"
                        :class="[ filter.messageStatusId == messageStatusTypes.Deleted ? 'has-badge-info' : 'has-badge-dark' ]" />
                    </span>
                  </a>
                </li>
              </ul>
              <div class="tags has-addons"
                style="margin-left: auto">
                <span class="tag is-dark">Last fetched</span>
                <span v-if="!isTableLoading"
                  class="tag is-success">{{ $filters.formatDateTimeLocale(new Date(), $userInfo.locale) }}</span>
              </div>
            </div>
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.messageId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  <router-link v-if="canEditMessage"
                    :to="{ path: entity.messageId }"
                    append>
                    {{ entity.messageType }}
                  </router-link>
                  <span v-else>{{ entity.messageType }}</span>
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.regoNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.claimNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.customerName }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.uid }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.quoteNo }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.vehicleDescription }}
                </td>
                <td :class="{ 'has-text-danger' : entity.statusId === messageStatusTypes.Failed }">
                  {{ entity.messageDateUtc ? $filters.formatDateTimeLocale(entity.messageDateUtc, $userInfo.locale) : $filters.formatDateTimeLocale(entity.createdDate, $userInfo.locale) }}
                </td>
                <td class="is-content-width">
                  <div class="has-text-centered">
                    <router-link v-if="canEditMessage"
                      :to="{ path: entity.messageId }"
                      class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="View/Edit XML"
                      append>
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </router-link>
                    <a v-if="canLoadMessage(entity.messageTypeId)"
                      class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Load Message"
                      @click="load(index)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-download mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canMarkMessageRead"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Read"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Read)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email-open mdi-24px" />
                      </span>
                    </a>
                    <!-- <a v-if="canPrintMessage(entity.messageTypeId)"
                      class="button is-black is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Print Message"
                      @click="print(entity.messageId, entity.messageTypeId)">
                      <span class="icon is-medium ">
                        <i class="mdi mdi-printer mdi-24px" />
                      </span>
                    </a> -->
                    <a v-if="canMarkMessageUnread"
                      class="button is-info is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Mark as Unread"
                      @click="markMessageAsUnread(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-email mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canResendMessage(entity.statusId)"
                      class="button is-link is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Resend Message"
                      @click="markMessageAsToSend(entity.messageId)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-send mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canDeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      data-tooltip="Delete Message"
                      @click="showMessageActionConfirmModal(entity.messageId, messageStatusTypes.Deleted)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                    <a v-if="canUndeleteMessage"
                      class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                      :data-tooltip="[entity.messageDirection === 0 ? 'Restore to Read' : 'Restore to Sent']"
                      @click="unDeleteMessage(entity.messageId, entity.messageDirection)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete-restore mdi-24px" />
                      </span>
                    </a>
                  </div>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <load-quote-modal :active.sync="isLoadQuoteModalActive"
      @close="closeModal()"
      @previous="previous()"
      @next="next()"
      @load="loadMessage()"
      :show-icon="false"
      :previous-disabled="currentStep === steps.CreateAssign.id"
      :next-disabled="currentStep === lastStep.id"
      :continue-disabled="!enableContinue">
      <p slot="text-title">EstImage Load Quote</p>
      <div slot="text-content">
        <load-quote-steps v-model="loadOptions"
          :current-step="currentStep"
          :steps="steps"
          ecom-type="estimage" />
      </div>
    </load-quote-modal>
    <confirm-modal :active.sync="isMessageOperationConfirmModalActive"
      @ok="changeMessageStatus(selectedMessageId, messageOperationTypeId)"
      @cancel="isMessageOperationConfirmModalActive = false"
      ok-text="Yes">
      <p slot="text-title">EstImage Message</p>
      <div slot="text-content">
        <span>Message will be moved to</span>
        <span class="has-text-weight-bold">
          {{ getFolderName(messageOperationTypeId) }}.
        </span><br>
        <span>Are you sure?</span>
      </div>
    </confirm-modal>
    <confirm-modal :active.sync="isEarlierMessageModalActive"
      @ok="load(minimalMessageDateIndex)"
      @cancel="isEarlierMessageModalActive = false"
      ok-text="Procced">
      <p slot="text-title">Earlier EstImage Message Exists</p>
      <div slot="text-content">
        <span>Please load an earlier message type <span v-if="entitySummaries.length"
          class="has-text-weight-bold">{{ entitySummaries[minimalMessageDateIndex].messageType }}</span></span>
        <br>
        <br>
        <span>Do you want to continue?</span>
      </div>
    </confirm-modal>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="gotoQuote(true)"
      @cancel="gotoQuote(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Go to Quote</p>
      <p slot="text-content">
        <span v-if="savedLoadOptions && savedLoadOptions.isCreateQuote && savedLoadOptions.messageTypeId === messageTypes.EstImageRequestForQuote">Quote created</span>
        <span v-else-if="savedLoadOptions && !savedLoadOptions.isCreateQuote && savedLoadOptions.messageTypeId === messageTypes.EstImageRequestForQuote">Quote assigned</span>
        <span v-else>Message loaded</span>
        successfully. Go to quote
        <span class="has-text-primary has-text-weight-bold">{{ `${quoteNo}` }}</span> now?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes, MessageStatusTypes, MessageTypes } from '@/enums'
import StoreMixin from './storeMixin'
import { Columns } from './columns'
import { Steps, CustomerOptionTypes, LoadQuoteModal, LoadQuoteSteps } from '@/components/LoadQuoteSteps'
import BulmaTable from '@/components/BulmaTable'
import EstimageMessageService from './EstimageMessageService'
import EstimageMessageListSideMenu from './EstimageMessageListSideMenu.vue'
import { ConfirmModal } from '../../components/BulmaModal'
import Guid from '@/components/Guid'
import EstimageMessageValidation from './EstimageMessageValidation'
import _cloneDeep from 'lodash/cloneDeep'
import NavService from '@/components/NavService'
import QuoteRoutes from '@/views/quote/route-types'

export default {
  name: 'EstimageMessageListView',
  components: {
    EstimageMessageListSideMenu,
    AppDetailHeader,
    BulmaTable,
    ConfirmModal,
    LoadQuoteModal,
    LoadQuoteSteps
  },
  mixins: [StoreMixin, EstimageMessageValidation],
  props: {
    value: null
  },
  data() {
    return {
      isTableLoading: false,
      isEarlierMessageModalActive: false,
      minimalMessageDateIndex: 0,
      entitySummaries: {},
      countSummaries: null,
      totalRows: 0,
      quoteNo: '',
      filter: {
        quoteNo: '',
        rego: '',
        claimNo: '',
        messageTypeId: 0,
        messageStatusId: MessageStatusTypes.UnRead,
        sortColumn: Columns[Columns.length - 2].name,
        sortOrder: Columns[Columns.length - 2].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      loadOptions: {
        messageId: Guid.empty(),
        messageTypeId: MessageTypes.EstImageRequestForQuote,
        quoteId: Guid.empty(),
        insurerId: Guid.empty(),
        isCreateQuote: true,
        customerId: Guid.empty(),
        originalCustomerId: Guid.empty(),
        customerOption: CustomerOptionTypes.Create,
        title: '',
        customerName: '',
        firstname: '',
        lastname: '',
        isPerson: false,
        regoNo: '',
        vehicleId: Guid.empty(),
        isOverwriteVehicle: false,
        isOverwriteOrmMessageNo: false
      },
      selectedRow: null,
      steps: Steps,
      isLoadQuoteModalActive: false,
      isConfirmModalActive: false,
      isMessageOperationConfirmModalActive: false,
      savedLoadOptions: null,
      selectedMessage: null,
      quoteExists: false,
      vehicleExists: false,
      currentStep: Steps.CreateAssign.id,
      unreadOffset: 'has-badge-child-offset2',
      readOffset: 'has-badge-child-offset2',
      toSendOffset: 'has-badge-child-offset2',
      sentOffset: 'has-badge-child-offset2',
      deletedOffset: 'has-badge-child-offset2',
      messageOperationTypeId: ''
    }
  },
  computed: {
    messageStatusTypes() {
      return MessageStatusTypes
    },
    messageTypes() {
      return MessageTypes
    },
    columns() {
      return Columns
    },
    canEditMessage() {
      return this.$user.info.isSupportUser
    },
    canMarkMessageRead() {
      return this.filter.messageStatusId === MessageStatusTypes.UnRead
    },
    canMarkMessageUnread() {
      return (
        this.filter.messageStatusId === MessageStatusTypes.Read ||
        this.filter.messageStatusId === MessageStatusTypes.SyncFailed ||
        this.filter.messageStatusId === MessageStatusTypes.Synced
      )
    },
    canDeleteMessage() {
      return this.filter.messageStatusId !== MessageStatusTypes.Deleted && this.$user.info.isSupportUser
    },
    canUndeleteMessage() {
      return this.filter.messageStatusId === MessageStatusTypes.Deleted && this.$user.info.isSupportUser
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    lastStep() {
      if (this.steps.OverwriteVehicle.enabled) {
        return this.steps.OverwriteVehicle
      } else {
        return this.steps.UpdateCustomer
      }
    },
    enableContinue() {
      return this.lastStep.id === this.currentStep
    }
  },
  watch: {},
  created() {
    this.getEntitySummaries()
    this.getCountSummaries()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.quoteNo = ''
      this.filter.rego = ''
      this.filter.claimNo = ''
      this.filter.messageTypeId = 0
      this.filter.sortColumn = Columns[Columns.length - 2].name
      this.filter.sortOrder = Columns[Columns.length - 2].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    async addNew() {
      this.addStoreItem()
    },
    getCount(messageStatusId) {
      if (this.countSummaries) {
        const summary = this.countSummaries.find((c) => c.statusId === messageStatusId)
        let count = 0
        if (messageStatusId === MessageStatusTypes.ToSend) {
          const failedSummary = this.countSummaries.find((c) => c.statusId === MessageStatusTypes.Failed)
          count = summary.messageCount + failedSummary.messageCount
        } else {
          count = summary.messageCount
        }
        this.setBadgeOffset(messageStatusId, count)
        return count
      }
    },
    setBadgeOffset(messageStatusId, count) {
      let offset = 'has-badge-child-offset2'
      if (count >= 10 && count <= 99) {
        offset = 'has-badge-child-offset3'
      } else if (count >= 100 && count <= 999) {
        offset = 'has-badge-child-offset4'
      } else if (count >= 1000 && count <= 9999) {
        offset = 'has-badge-child-offset5'
      } else if (count >= 10000) {
        offset = 'has-badge-child-offset6'
      }
      switch (messageStatusId) {
        case MessageStatusTypes.UnRead:
          this.unreadOffset = offset
          break
        case MessageStatusTypes.Read:
          this.readOffset = offset
          break
        case MessageStatusTypes.ToSend:
          this.toSendOffset = offset
          break
        case MessageStatusTypes.Sent:
          this.sentOffset = offset
          break
        case MessageStatusTypes.Deleted:
          this.deletedOffset = offset
          break
      }
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    previous() {
      this.currentStep--
      if (this.currentStep === this.steps.CreateAssign.id) {
        this.$v.loadOptions.quoteId.$reset()
      } else if (this.currentStep === this.steps.SelectInsurer.id) {
        this.$v.loadOptions.insurerId.$reset()
      } else if (this.currentStep === this.steps.UpdateCustomer.id) {
        this.$v.loadOptions.customerId.$reset()
      }
    },
    next() {
      switch (this.currentStep) {
        case Steps.CreateAssign.id:
          this.$v.loadOptions.quoteId.$touch()
          if (!this.$v.loadOptions.quoteId.$error || this.loadOptions.isCreateQuote) {
            this.currentStep++
          }
          break
        case Steps.SelectInsurer.id:
          this.$v.loadOptions.insurerId.$touch()
          if (!this.$v.loadOptions.insurerId.$error) {
            this.currentStep++
          }
          break
        case Steps.UpdateCustomer.id:
          this.$v.loadOptions.customerId.$touch()
          if (!this.$v.loadOptions.customerId.$error || this.loadOptions.customerOption === CustomerOptionTypes.Create) {
            this.currentStep++
          }
          break
        case Steps.OverwriteVehicle.id:
          break
      }
    },
    closeModal() {
      this.isLoadQuoteModalActive = false
      this.reset()
    },
    reset() {
      this.loadOptions.messageId = Guid.empty()
      this.loadOptions.messageTypeId = MessageTypes.EstImageRequestForQuote
      this.loadOptions.quoteId = Guid.empty()
      this.loadOptions.isCreateQuote = true
      this.loadOptions.insurerId = Guid.empty()
      this.loadOptions.customerOption = CustomerOptionTypes.Create
      this.loadOptions.isOverwriteVehicle = false
      this.loadOptions.customerId = Guid.empty()
      this.loadOptions.title = ''
      this.loadOptions.customerName = ''
      this.loadOptions.firstname = ''
      this.loadOptions.lastname = ''
      this.loadOptions.isPerson = false
      this.loadOptions.regoNo = ''
      this.loadOptions.vehicleId = Guid.empty()
      this.loadOptions.isOverwriteOrmMessageNo = false
      this.loadOptions.quoteExist = false
      this.currentStep = Steps.CreateAssign.id
      this.selectedMessage = null
      // Reset validation objects
      this.$v.loadOptions.$reset()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await EstimageMessageService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    async getCountSummaries() {
      this.countSummaries = await EstimageMessageService.getCountSummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    canResendMessage(messageStatusId) {
      const canResend =
        this.filter.messageStatusId === MessageStatusTypes.Sent ||
        (this.filter.messageStatusId === MessageStatusTypes.ToSend && messageStatusId === MessageStatusTypes.Failed)
      return canResend
    },
    canPrintMessage(messageTypeId) {
      const canPrint =
        messageTypeId === MessageTypes.EstImageRequestForQuote ||
        messageTypeId === MessageTypes.EstImageAuthorise ||
        messageTypeId === MessageTypes.EstimageQuote ||
        messageTypeId === MessageTypes.EstimageInvoice
      return canPrint && this.filter.messageStatusId === MessageStatusTypes.Read
    },
    canLoadMessage(messageTypeId) {
      const canLoad =
        messageTypeId === MessageTypes.EstImageRequestForQuote ||
        messageTypeId === MessageTypes.EstImageAuthorise ||
        messageTypeId === MessageTypes.EstImageRegoUpdate ||
        messageTypeId === MessageTypes.EstImageExcessUpdate ||
        messageTypeId === MessageTypes.EstImageCancel

      return this.filter.messageStatusId === MessageStatusTypes.UnRead && !this.$licenceExpired && canLoad
    },
    tabClicked(messageSatusId) {
      if (this.filter.messageStatusId !== messageSatusId) {
        this.filter.messageStatusId = messageSatusId
        this.filter.pageIndex = 1
        this.selectedRow = null
        this.persistFilter()
        this.getEntitySummaries()
        this.getCountSummaries()
      }
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    showMessageActionConfirmModal(messageId, messageStatusTypeId) {
      this.messageOperationTypeId = messageStatusTypeId
      this.selectedMessageId = messageId
      this.isMessageOperationConfirmModalActive = true
    },
    changeMessageStatus(messageId, messageStatusTypeId) {
      switch (messageStatusTypeId) {
        case MessageStatusTypes.Read:
          this.updateMessageStatus(messageId, MessageStatusTypes.Read)
          break
        case MessageStatusTypes.Unread:
          this.updateMessageStatus(messageId, MessageStatusTypes.UnRead)
          break
        case MessageStatusTypes.ToSend:
          this.updateMessageStatus(messageId, MessageStatusTypes.ToSend)
          break
        case MessageStatusTypes.Deleted:
          this.updateMessageStatus(messageId, MessageStatusTypes.Deleted)
          break
      }
      this.isMessageOperationConfirmModalActive = false
    },
    getFolderName(messageStatusTypeId) {
      switch (messageStatusTypeId) {
        case MessageStatusTypes.Read:
          return 'Read'
          break
        case MessageStatusTypes.Unread:
          return 'Unread'
          break
        case MessageStatusTypes.ToSend:
          return 'To Send'
          break
        case MessageStatusTypes.Deleted:
          return 'Deleted'
          break
      }
    },
    async updateMessageStatus(messageId, messageStatusId) {
      try {
        await EstimageMessageService.updateMessageStatus(messageId, messageStatusId)
      } catch (e) {
        this.$notification.openMessageXhrError('EstImage Message', e)
      }
      this.getEntitySummaries()
      this.getCountSummaries()
    },
    markMessageAsUnread(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.UnRead)
    },
    markMessageAsToSend(messageId) {
      this.updateMessageStatus(messageId, MessageStatusTypes.ToSend)
    },
    unDeleteMessage(messageId, messageDirection) {
      if (messageDirection === 0) {
        // Incoming messages
        this.updateMessageStatus(messageId, MessageStatusTypes.Read)
      } else {
        // Outgoing messages
        this.updateMessageStatus(messageId, MessageStatusTypes.Sent)
      }
    },
    async load(index) {
      this.isEarlierMessageModalActive = false

      // get message in given index
      // entity summaries is an array
      this.selectedMessage = this.entitySummaries[index]
      // get a list of requests in the same claim
      const requestsInSameClaim = this.getRequestsInSameClaim()

      // if there are more than one request in the same claim
      // get the entity with the minimum message no
      // and set the selectedMessage to that entity
      if (requestsInSameClaim.length > 0) {
        // get the entity with the minimum message no with same rego and claim number in the page context
        const mininumMessage = this.getEntityWithMinimumMessageDate(requestsInSameClaim)

        // if current selected message is not the minimum message no entity
        if (this.selectedMessage.messageDateUtc > mininumMessage.messageDateUtc) {
          // asign state to the minimum message no entity
          this.minimalMessageDateIndex = mininumMessage.rowNumber - 1
          // open up the confirmation dialog
          this.isEarlierMessageModalActive = true
          return
        }
      }

      // Set loadOptions properties based on the selectedMessage's properties
      this.loadOptions.messageId = this.selectedMessage.messageId
      this.loadOptions.messageTypeId = this.selectedMessage.messageTypeId
      this.loadOptions.regoNo = this.selectedMessage.regoNo

      // Check the messageTypeId to determine the next steps

      // If the message type is a request for quote, perform additional actions
      if (this.selectedMessage.messageTypeId === MessageTypes.EstImageRequestForQuote) {
        this.assertVehicleExists(this.selectedMessage.regoNo)
        await this.assertQuoteExists(this.selectedMessage.claimNo, this.selectedMessage.regoNo, this.selectedMessage.uid)
        this.loadOptions.isPerson = true
        const names = this.selectedMessage.customerName.split(' ')
        this.loadOptions.firstname = names.length > 1 ? names.slice(0, -1).join(' ') : ''
        this.loadOptions.lastname = names[names.length - 1]
        this.loadOptions.customerName = this.selectedMessage.customerName
        this.isLoadQuoteModalActive = true
      } else if (this.selectedMessage.messageTypeId === MessageTypes.EstImageAuthorise) {
        this.loadOptions.quoteNo = this.selectedMessage.quoteNo
        this.loadMessage()
      } else if (
        this.selectedMessage.messageTypeId === MessageTypes.EstImageRegoUpdate ||
        this.selectedMessage.messageTypeId === MessageTypes.EstImageExcessUpdate ||
        this.selectedMessage.messageTypeId === MessageTypes.EstImageCancel) {
        this.loadOptions.quoteNo = this.selectedMessage.quoteNo
        this.loadMessage()
      } else {
        this.loadMessage()
      }
    },

    async loadMessage() {
      this.$showSpinner('Loading quote')
      try {
        this.isLoadQuoteModalActive = false
        const result = await EstimageMessageService.loadMessage(this.loadOptions)
        this.quoteId = result.data?.quoteId
        this.quoteNo = result.data?.quoteNo
        this.getEntitySummaries()
        this.getCountSummaries()
        if (this.loadOptions.isCreateQuote && this.loadOptions.messageTypeId === MessageTypes.EstImageRequestForQuote) {
          this.createAuditTrail(this.quoteId, 'insert', '')
        } else {
          // TODO: create update AuditTrail entry
        }
        this.savedLoadOptions = _cloneDeep(this.loadOptions)
        this.isConfirmModalActive = true
      } catch (e) {
        throw e
      } finally {
        this.reset()
        this.$hideSpinner()
      }
    },
    getRequestsInSameClaim() {
      return this.entitySummaries.filter(
        (x) =>
          x.claimNo === this.selectedMessage.claimNo &&
          x.regoNo === this.selectedMessage.regoNo &&
          x.uid === this.selectedMessage.uid &&
          x.messageId !== this.selectedMessage.messageId
      )
    },
    getEntityWithMinimumMessageDate(entities) {
      const entity = entities.reduce((prev, current) => {
        return prev.messageDateUtc < current.messageDateUtc ? prev : current
      })
      return entity
    },
    async assertVehicleExists(rego) {
      this.vehicleExists = await EstimageMessageService.assertVehicleExists(rego)
      this.steps.OverwriteVehicle.enabled = this.vehicleExists
    },
    async assertQuoteExists(claimNo, rego, uid) {
      this.quoteExists = await EstimageMessageService.assertQuoteExists(claimNo, rego, uid)
      this.loadOptions.quoteExist = this.quoteExists
      this.loadOptions.isCreateQuote = !this.quoteExists
    },
    async createAuditTrail(entityId, operationType, deepdiff) {
      const quote = await EstimageMessageService.getQuoteEntity(entityId)
      const audtiTrailItem = {
        auditTrailId: null,
        performedAt: new Date().toISOString,
        userId: this.$userInfo.userId,
        userName: this.$userInfo.displayName,
        userRole: this.$userInfo.isSupportUser ? 'Support' : 'Normal',
        userAgent: '',
        userHostAddress: '',
        userHostName: '',
        url: '',
        companyId: this.$userInfo.companyId,
        entityId: entityId,
        entityType: 'Quote',
        entityReference: `${quote.prefix}${quote.quoteNo}${quote.suffix}`,
        operationType: operationType,
        deepdiff: operationType === 'update' ? JSON.stringify(deepdiff) : null,
        entity: operationType === 'insert' ? JSON.stringify(quote) : null
      }
      EstimageMessageService.postAuditTrailItem(audtiTrailItem)
    },
    async gotoQuote(goto) {
      this.isConfirmModalActive = false
      if (goto) {
        const accessModel = await NavService.getAccess('A077', 'detail')
        let url = ''
        if (accessModel.version === '2') {
          const routeData = this.$router.resolve({
            name: QuoteRoutes.QuoteHeader.name,
            params: { quoteId: this.quoteId }
          })
          url = routeData.href
        } else {
          url = `${process.env.VUE_APP_ROOT_URI}/modules/handlers/QuoteLoading.ashx?quoteId=${this.quoteId}&mode=5`
        }
        window.open(url, '_blank')
      }
    }
  }
}
</script>